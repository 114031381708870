
.container{
  padding:40px;
  margin:40px;
  height: min(740px, auto);
}

.container h3 {
  text-align: center;
  font-size: 42px;
  color:#292929;
  font-weight: bold;
  margin-bottom: 30px;
}


.boxContainer {
  height: 240px;
  max-width: 320px;
  border: 2px solid #9a9b9a;
  padding: 4px;
  margin-top: 40px;
  background-color: white;
  margin-left: 46px;
  border-bottom: 8px solid #4590f8;
}

.boxContainer:hover {
  cursor: pointer;
  background-color: #4590f8;
}


.wrapper {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(auto-fill, 25% );
  
}



.box {
  padding: 10px;
  font-size: 150%;
  font-weight: 500;
}

.a {
  grid-column: 1 / 3;
  color:#0852b9;
  border-bottom: 1px solid grey;
  font-size: 16px;
}

.b {
  grid-column: 3 / 5 ;
  color:#0852b9;
  border-bottom: 1px solid grey;
  text-align: right;
  font-size: 14px;
 
}

.c {
  grid-column: 1 / 5;
  grid-row: 2;
  padding-top: 12px;
  color:#131313;
  font-size: 24px;
  line-height: 1.3;
  min-height: 80px;

}

.d {
  grid-column: 1 / 5;
  grid-row: 3;
  font-size: 18px;
  color:#be7e07;
  padding: 20px 0px 10px 10px;
}

.e {
  grid-column: 1 / 5;
  grid-row: 4;
  font-size: 16px;
  color:#2a18c5;
  position: absolute;
  bottom: 24px;
  border-bottom: 2px solid navy;
  padding: 0px;
  margin-left: 10px;
}

.seeMoreDiv{
  padding-top: 48px;
  text-align: center;
}

.seeMoreSpan{
  cursor: pointer;
  font-size: 24px;
  color:#2a18c5;
  text-decoration-line: underline;
  text-decoration-color: rgb(148, 148, 148);
  text-decoration-thickness: 2px;
  text-underline-offset: 8px;
}

.ModalText {
  font-size: 17px;
  line-height: 1.3;
  color: #292929;
  padding: 14px 10px 12px 2px;
}


.pagination{
  border: 1px solid #bebebe;
  font-size: 20px;
  padding: 16px;
  margin: 22px 2px 24px 2px;
  border-radius: 12px;
}


@media only screen and (max-width: 1200px) {

  .a {
    font-size: 10px;
  }

  .c {
      font-size: 21px;
      width: 96%;
    }
  
  .d {
      padding-top: 20px;
      font-size: 22px;
    }

}



@media only screen and (max-width: 600px) { 

    .backgroundGrey {
      height: 100%;
      background-color: rgb(255, 254, 254);
    }
  
    .container {
      padding: 0px;
      margin: 20px;
      height: 100%;
    }
  
    .container h3 {
      font-size: 28px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  
    .boxContainer {
      margin-top: 20px;
      margin-left: 2px;
    }

    .seeMoreDiv{
      padding-top: 30px;
    }

    .seeMoreSpan{
      font-size: 18px;
      line-height: 1.4;
    }
    .a, .b {
      font-size: 17px;
    }
    .c {
      font-size: 24px;
      line-height: 1.3;
      width:96%;
    }


  }