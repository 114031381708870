.footerContainer{
  width:100%;
  height:auto 0px;
  background-color: #bcc7cf;
  padding-top:40px;
  padding-bottom: 30px;
}

.footerImg1 {
  height: 160px;
  width:auto;
  background: url('./logo_bulgarian.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin:4px;
}
.footerImg2 {
  height: 160px;
  width:auto;
  background: url('./logo_1.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin:4px;
}
.footerImg3 {
  height: 160px;
  width:auto;
  background: url('./logo_Italias.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin:4px;
}

.footerDiv{
  padding: 10px 220px 46px 220px;
  text-align: center; 
  font-size: 20px;
  line-height: 1.3;
}

.FollowUs{
  text-transform: uppercase;
  color:white;
  font-size: 20px;
}

.AllRigts{
  color:white;
  text-align: right;
  font-size: 16px;
}

.FollowUs img{
  width:30px;
  height: auto;
  padding: 0px 8px 4px 14px;
  cursor: pointer;
}




@media only screen and (max-width: 1200px) {
  .footerImg1 {
    height: 100px;
  }

  .footerImg2 {
    height: 100px;
  }

  .footerImg3 {
    height: 100px;
  }

}



@media only screen and (max-width: 680px) {
  
  .footerDiv{
    padding: 10px;
    font-size: 18px;
  }

  .FollowUs {
    font-size: 18px;
    padding-top: 20px;
    text-align: center;
  }

  .FollowUs img{
    width:24px;
   
  }

  .footerImg1, .footerImg2, .footerImg3 {
    margin: 10px;
  }

  .AllRigts {
    text-align: center;
    padding-top: 18px;
  }
  
}
