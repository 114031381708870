.container{
  padding:80px;
  margin:40px;
  margin-top: 460px;
}

.multimedia{
  font-size: 32px;
  font-weight: bold;
  color: #0e67e2;
  margin-top: 24px;
  position: absolute;
}

.Tab{
  font-size: 20px;
  border: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.box {
  color: #fff;
  border-radius: 2px;
}

.a {
  grid-column: 1 / 3;
  grid-row: 1;
}
.b {
  grid-column: 3 ;
  grid-row: 1 / 3;
}
.c {
  grid-column: 1 ;
  grid-row: 2 ;
}
.d {
  grid-column: 2;
  grid-row: 2;
}



.e {
  grid-column: 1 / 3;
  grid-row: 1;
}
.f {
  grid-column: 3 ;
  grid-row: 1 / 3;
}
.g {
  grid-column: 1 ;
  grid-row: 2 ;
}
.h {
  grid-column: 2;
  grid-row: 2;
}


@media only screen and (max-width: 1200px) { 
.container{
  margin-top: 1010px;
}
.multimedia {
  font-size: 28px;
  margin-top: -15px;
}
}


@media only screen and (max-width: 600px) { 
  .container{
    padding:10px;
    margin:2px;
    margin-top:80px;
  }
  .multimedia{
    font-size: 26px;
    margin-top: -24px;
  }
  .Tab{
    font-size: 13px;
    border: none;
  }

}
