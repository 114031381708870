.container{
  color:#363636;
  line-height: 1.4;
  padding:20px;
  height: min(auto, 1200px);
}

.titleMain{
  font-size: 44px;
  text-align: left;
  padding:20px 0px 6px 0px;
  font-weight: bold;
  color:#063A81;
  font-family: 'Noto Sans', sans-serif !important;
  border-bottom: 1px solid #063A81;
  margin-bottom: 80px;
}



.item{
  text-align: left;
  margin-bottom: 40px;
}

.itemVideo{
  text-align: left;
}

.item2{
  text-align: left;
  margin-bottom: 40px;
  width:900px;
}

.itemName{
  font-size: 30px !important;
  font-weight: 500;
  color: #063A81;
  bottom: 20px;
  text-align: left;
 
}

.input{
  height:60px;
  border-radius: 20px;
  position: relative;
  bottom: 10px;
}

.input90{
  height:60px;
  border-radius: 20px;
  position: relative;
  bottom: 10px;
  width:90%;
}

.input2{
  height:60px;
  border-radius: 20px;
  position: relative;
  bottom: 10px;
  width: 460px;
  margin-left:200px;
}

.input3{
  height:60px;
  border-radius: 20px;
  position: relative;
  bottom: 10px;
  width: 460px;
  margin-left:140px;
}

.btnSave{
  height: 60px;
  background-color: #063A81;
  font-size: 18px;
  width: 220px !important;
}

.btnSave:hover{
  height: 60px;
  background-color: #1257b8 !important;
  border: 1px solid #0b4596;
  font-size: 18px;
}

.btnSaveCancel{
  height: 60px;
  background-color: #154b97;
  font-size: 18px;
  width: 220px !important;
  opacity: 0.3;
}

.btnSaveCancel:hover{
  height: 60px;
  background-color: #154b97;
  font-size: 18px;
  width: 220px !important;
  opacity: 0.3;
}


.btnCancel{
  height: 60px;
  font-size: 18px;
  color:#E74D3D;
  border: 2px solid #E74D3D;
  width: 220px !important;
}
.btnCancel:hover{
  height: 60px;
  color:#d83f2e !important;
  border: 2px solid #d83f2e !important;
  font-size: 18px;
}




@media only screen and (min-width: 1200px) and (max-width: 1440px)  {
  
  .itemName{
    font-size: 28px !important;
    font-weight: 500;
    color: #063A81;
    bottom: 20px;
  }


  .input2{
    height:60px;
    border-radius: 20px;
    position: relative;
    bottom: 10px;
    width: 300px;
    margin-left:60px;
  }
  
  .input3{
    height:60px;
    border-radius: 20px;
    position: relative;
    bottom: 10px;
    width: 400px;
    margin-left:-200px;
  }

}


@media only screen and (min-width: 991px) and (max-width: 1200px)  {
  
  .itemName{
    font-size: 24px !important;
    font-weight: 500;
    color: #063A81;
    bottom: 20px;
  }

}


@media only screen and (min-width: 600px) and (max-width: 990px)  {
  
  .itemName{
    font-size: 20px !important;
    font-weight: 500;
    color: #063A81;
    bottom: 20px;
  }

}


@media only screen and (max-width: 600px) {

  .container {
    padding: 10px;
  }

  .titleMain {
    font-size: 31px;
    padding: 10px 0px 12px 0px;
  }

  .itemName{
    font-size: 18px !important;
    font-weight: 500;
    color: #063A81;
    bottom: 0px;
   
  }

  .btnSave, .btnCancel, .btnSave:hover, .btnCancel:hover {
    height: 50px;
    font-size: 16px;
    width: 160px !important;
    text-align: center;
    margin-right:10px;
  }

}
