
.container{
  color:#363636;
  line-height: 1.4;
  padding:20px;
  height: auto;
  min-height: 600px;
}

.titleMain{
  font-size: 44px;
  text-align: left;
  padding:20px 0px 30px 0px;
  font-weight: bold;
  color:#063A81;
  font-family: 'Noto Sans', sans-serif !important;
}

.text{
  padding-bottom:14px;
  font-size: 18px;
  text-align: justify;

}

.textWebUrl a{
  color:#063A81 !important;
  font-size: 22px !important;
  text-align: justify;
  font-weight: 600;
  padding-left: 50px;
  line-height: 2.2;
}


.textSocialMedia a{
  color: #063A81 !important;
  font-size: 22px !important;
  text-align: justify;
  font-weight: 600;
  padding-left: 52px;
  top: 8px;
  position: relative;
  line-height: 1.6;
}


.text p{
  padding-top:14px;
}

.text h3{
  color:#363636;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  padding:30px 0px 4px 0px;
}

.container2{
  display: flex;
  height:160px;
  border:1px solid green;
}

.element2{
  margin: auto;
}

.box{

  width:100%;
  margin:20px 0px;
}

.rowImage{
  /* text-align: center;
  display:block;
  border:2px solid #82A9D4; */
  border: 2px solid #82A9D4;
  display: flex;
  justify-content: center;
  height: 204px;
}

.rowImageWithYoutBorder{
  text-align: center;
  display:block; 
  padding: 6px;
}


.rowImage img{
  height: 180px;
  position: relative;
  height: auto;
  max-height: 190px;
  position: relative;
  padding: 10px;
  max-width: 230px;
}


.rowImageWithYoutBorder img{
  height: 184px;
  position: relative;
  padding: 6px;
  /* width: 78%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}



.rowTextPart1{
  text-align: left;
  padding-left:20px;
  color:white;
  max-width:70%;
  width:70%;
  font-size: 30px;
  background-color: #82A9D4;
  line-height: 1.6;
  min-height: 160px;
  display: inline-flex;
  align-items: center;
  top: -28px;
    position: relative;
    cursor: pointer;
}

.rowTextPart1 span{
  width: 600px;
  padding-left:20px;
  font-weight: bold;
}


.rowTextPart2{
  text-align: right;
  display:inline-block;
  width:20%;
  background-color: #82A9D4;
  position: relative;
  margin-top: 8%;
  cursor: pointer;
}


.collapseBackGround{
  background-color: #82A9D4;
  padding:40px;
  border-top: 2px solid white;
}


.collapseBackGround .svg{
  padding-right:16px;
  position:relative;
  top:10px;
}

.collapseBackGround .title{
  color:white;
  font-size: 20px;
  font-weight: bold;
}

.collapseBackGround .text{
  color: #063A81;
  font-size: 22px;
  font-weight: bold;
  padding-right: 180px;
  padding-left: 50px;
  padding-top:8px;
  padding-bottom: 28px;
  display: block;
  text-align: left;
}

.divVasiko{
  background-color: #82A9D4;
  height: 208px;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px)  {
  
  .rowImage{
    height: 184px;
  }

  .rowImageWithYoutBorder img{
    height: 164px;
    position: relative;
    padding: 4px;
  }
  /* .rowImage img {
    height: 160px;
  } */

  .divVasiko{
    height: 188px;
  }

}


@media only screen and (max-width: 600px) {

  .container {
    padding: 10px;
  }

  .titleMain {
    font-size: 31px;
    padding: 10px 0px 12px 0px;
  }

  .text {
    font-size: 17px;
  }

  .text h3 {
    line-height: 1.3;
    padding: 10px 0px 4px 0px;
  }



  .rowTextPart1{
    padding-top: 0px;
    text-align: center;
    max-width:100%;
    width:100%;
    padding-left: 0px;
    min-height: 160px;
  }

  .rowTextPart1 span {
    padding-left: 0px;
  }

  .rowTextPart2{
    padding-top:0px;
    text-align: center;
    padding-left:0px;
    width:100%;
    margin-top:0%;
  }


  .collapseBackGround .text {
    padding-top:20px;
    padding-right: 0px;
    padding-left: 0px;
  }


  .rowImage{
    height: 224px;
  }
  
  .rowImageWithYoutBorder{
    height: 224px;
  }
  
  


}
