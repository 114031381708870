.header {
  position: relative;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  min-width: 100%;
  min-height: 76px;
  max-height: 130px;
  padding: 0px 0px;
  background-color: #bcc7cf;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  z-index: 3;
}

.flexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div{
  height: 68px;
  position: relative;
}

.Language{
  margin-left: -160px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 18px;
  padding:20px;
  /* border-right: 2px solid rgb(182, 7, 7); */
}

.Login{
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  padding:20px;
  border-left: 1px solid rgb(32, 32, 32);
}

.LoginLink, .LoginLink a{
  color:black;
  font-weight: 500;
  cursor: pointer;
}

.LoginLink:hover, .LoginLink a:hover{
  text-decoration: underline; 
  color:rgb(41, 69, 194);
  text-underline-offset: 4px;
  font-weight: 500;
}



.app_logo {
  flex: 0 1 auto;
  max-height: 30px;
  cursor: pointer;
  aspect-ratio: 313 / 29;
}

.app_logo img {
  min-width: 250px !important;
}

.sponsor_logo__container {
  height: 60px;
  max-height: 40px;
}

.logo_normal {
  display:none;
  height: 75px;
  margin-right: 130px;
  position: relative;
  float: left;
  top: -15px;
}

.logo_normal1 {
  height: 45px;
  margin-right: 30px;
  position: relative;
  float: left;
  display: inline;
}

.epsa_logo_absolute {
  position: absolute;
  height: 60px;

}

.changeLanguage__Wrapper {
  position: absolute;
  right: 10px;
  transform: translateY(8px);
}

.changeLanguage__Wrapper span {
  cursor: pointer;
}

.activeLanguage {
  font-weight: 700;
}


.fillLogo{
  display: flex;
  justify-content: right;
  align-items: right;
  overflow: hidden;
  background-color: white;
  margin-top: 60px;
  top: -60px;
  right: 350%;
  position: relative;

}
.fillLogo img {
  flex-shrink: 0;
  max-width: 85%;
  max-height: 70%;
}

.signUpBtn{
  height: 46px !important;
  font-size: 18px !important;
  width: 160px;
  text-align: center !important;
  color:#275674;
  background-color: #275674;
  padding: 18px auto;
  color: #FFFFFF;
  margin:6px;
}
.signUpBtn:hover{
  background-color: #fff !important;
  color:#275674 !important;
  border:1px solid #d9d9d9 !important;
}

.hideMobile{
  display: block;
}

@media only screen and (max-width: 960px) {
  .logo_normal {
    height: auto !important;
    width: 70% !important;
    margin-left: 50px;
  }

  .app_logo img {
    width: 110%;
  }
  .fillLogo {
    right: 150%;
    top: -40px;
  }
  .fillLogo img {
    flex-shrink: 0;
    max-width: 100%;
    max-height: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .epsa_logo_absolute {
    right: 0px;
    transform: translate(0, -5px);
  }

  .changeLanguage__Wrapper {
    position: absolute;
    right: 10px;
    top: 0px;
  }

  .logo_normal {
    height: 60px;
    margin-right: 0px;
    position: relative;
    float: left;
    display: inline;
    top: -45px;
  }

  .Login span:nth-child(3) {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .header {
    justify-content: center;
    flex-wrap: wrap;
    min-height: 110px;
    max-height: 210px;
  }

  .epsa_logo_absolute {
    right: unset;
    transform: translate(calc((227.75px - 150.08px) / 2), -5px);
  }

  .app_logo img {
    width: 80%;
  }
  .logo_normal {
    display:inline;
    margin-right: 130px;
    position: relative;
    float: left;
    top: -44px;
  }

  .fillLogo {
    display: none;
}

}