.container {
  /* margin:40px; */
  /* text-align: center; */
}

.boxContainer {
  /* max-width: 360px;
  width: auto; */
  width: 360px;
  margin: 20px;
  margin-top: 40px;
  /* margin-left: 40px; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.readMoreContainer {
  padding-top: 30px;
  height: 50px;
  text-align: center;
}

.readMoreContainerSpan {
  padding: 10px 20px;
  font-size: 16px;
  color: rgb(84, 84, 238);
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid grey;

}

.wrapper {
  /* display: grid; */
  /* grid-gap: 10px; */
  grid-template-columns: repeat(auto-fill, 100px);
}



.box {
  padding: 6px;
  font-size: 150%;
  font-weight: 500;
}

.a {
  width: auto;
  grid-row: 1;
}

.a img {
  padding: 20px 0;
  height: 75px;
  width: auto;
}


.b {
  grid-column: 1/5;
  height: 84px;
  color: #044196;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
  grid-row: 2;
}

.c {
  min-height: 350px;
  max-height: 420px;
  margin-top: 26px;
  grid-column: 1/5;
  grid-row: 3;
  padding: 20px;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.3;
  text-align: justify;
}

.d {
  grid-column: 1/5;
  grid-row: 4;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  padding-bottom: 20px;
  font-weight: bold;
}

.e {
  grid-column: 1/5;
  grid-row: 5;
}

.ModalText {
  font-size: 17px;
  line-height: 1.3;
  color: #292929;
  padding: 14px 10px 12px 2px;
}

.ModalContact {
  font-size: 16px;
  line-height: 1.3;
  color: #141414;
  padding: 14px 10px 12px 2px;
}

.ModalText p {
  padding-top: 10px;
  text-align: justify;
}

@media only screen and (max-width: 1400px) {

  .a img {
    height: 65px;
    width: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .boxContainer {
    width: 215px;
    margin:20px
}
  .a img {
    height: 55px;
    width: auto;
  }
  .b {
    height: 100px;
    color: #044196;
    font-size: 21px;
  }

  .c{
    min-height: 730px;
    max-height: 730px;
  }
  .readMoreContainerSpan {
    padding: 10px 18px;

}

}

@media only screen and (max-width: 1000px) {

  .a img {
    height: 45px;
    width: auto;
  }
}
@media only screen and (max-width: 800px) {

  .a img {
    height: 43px;
    width: auto;
  }
}

@media only screen and (max-width: 600px) {

  
  .a img {
    height: 68px;
    width: auto;
  }
  .b {
    height: 70px;
  }

  .c{
    min-height: auto;
  }
  .container {
    padding: 0px;
    margin: 4px;
    height: 100%;
  }

  .boxContainer {
    margin-left: 20px;
    width:auto;
  }

  .readMoreContainer {
    padding-bottom: 30px;
  }

}