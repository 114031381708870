.list {
  list-style-type: none;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 20px;
 
}
.items a{
  /* font-family: 'Greek', sans-serif; */
  margin-right: 20px;
  margin-right: 0px;
  font-size: 22px;
  cursor: pointer;
  height: 24px;
  padding-top:10px;
  padding-right:10px;
  padding-left:10px;
  text-align:center;
  text-decoration:none;
  color:rgb(36, 36, 36);
  font-weight: 500;
  text-decoration: none;
}


.items a:hover{

  color:rgb(250, 250, 250);

}

.btn {
  display: none;
  position: absolute;
  right: 10px;
  bottom: 7px;
  padding: 5px;
  color: #000;
  font-size: 17px;
  border:none;
}









.dropbtn {
  background-color:#bcc7cf;
  color:rgb(36, 36, 36);
  border: none;
  margin-right: 20px;
  font-size: 22px;
  cursor: pointer;
  height: 24px;
  padding-top:4px;
  padding-right:10px;
  padding-left:10px;
  text-align:center;
  text-decoration:none;
  font-weight: 500;
  text-decoration: none;
}


.dropbtn:hover {color: #f6f7f8;}

.dropdown {
  position: relative;
  display: inline-block;
  padding-bottom: 16px;
  top: 7px;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #bcc7cf;
  min-width: 280px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding-top:2px;
  z-index:999 !important;
  margin-top: 16px;
  border-radius: 4px;
}

.dropdownContent a:first-child {
  border-top: 0px solid grey;
}

.dropdownContent a {
  color: rgb(22 22 22);
  padding: 14px;
  padding-bottom: 8px;
  text-decoration: none;
  display: block;
  font-size: 18px;
  text-align: left;
  margin-top: 0px;
  border-top: 1px solid grey;
}


.dropdownContent a:hover {color: #f6f7f8; background-color: #82A9D4; }

.dropdown:hover .dropdownContent {display: block;}

/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */




@media screen and (max-width: 1280px){

  .list {
      flex-direction: column;
      height: auto;
      top: 90px;
      right: 10px;
      position: absolute;
      background: #e4eff0;
      z-index: 999;
  }

  .items > a{
      width:100%;
      margin-right: 0px;
      cursor: pointer;
      height: 10px;
      padding-right: 10px;
      padding-left: 10px;
      width:100%;
      padding-top: 20px;
      padding-bottom: 20px;
      color:black;
      text-decoration: none;
  }
  .list a{
      width:100%!important;
  }
  .btn {
      display: block;
      font-size: 30px;
  }

  
}
@media screen and (max-width: 575px) {
  .list {
      flex-direction: column;
      height: auto;
      top: 118px;
      right: 4px;
      position: absolute;
      background: #bcc7cf;
      z-index: 999;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1379px){
  .items {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-right: 7px;
  }
}

/* @media screen and (min-width: 1380px){
  .items {
      padding-top: 35px;
  }
} */
