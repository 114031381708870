.container{
  color:#363636;
  line-height: 1.4;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding:20px;
}

.title{
  font-size: 36px;
  text-align: center;
  padding:20px 0px 30px 0px;
  font-weight: bold;
}

.text{
  padding-bottom:14px;
  font-size: 18px;
  text-align: justify;

}

.text p{
  padding-top:14px;
}

.text h3{
  color:#363636;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  padding:30px 0px 4px 0px;
}


@media only screen and (max-width: 600px) {

  .container {
    padding: 10px;
  }

  .title {
    font-size: 32px;
    padding: 10px 0px 12px 0px;
  }

  .text {
    font-size: 17px;
  }

  .text h3 {
    line-height: 1.3;
    padding: 10px 0px 4px 0px;
  }
}
