.container{
  width:100%;
  height:auto;
  color:#ffffff;
  background-color: rgba(11, 57, 128, 0.849);
  text-align: left;

}

.hide{
  display:block;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}
.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}


.fillLogo{
  display: flex;
  justify-content: right;
  align-items: right;
  overflow: hidden;
  background-color: white;
  margin-top:60px;
  margin-right:560px;

}
.fillLogo img {
  flex-shrink: 0;
  max-width: 70%;
  max-height: 70%;
}


.wrapperContainer{
  padding: 40px 60px 20px 300px;
}


.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px );
}

.a {
  padding-top:40px;
  grid-column: 1/8;
  font-size: 48px;
  font-weight: bold;
  line-height: 1.3;
  grid-row: 1;
}

.b {
  padding-top:40px;
  grid-column: 1/8;
  font-size: 22px;
  line-height: 1.5;
  grid-row: 2;
}

.c {
  margin-top:60px;
  grid-column: 1/8;
  font-size: 16px;
  text-decoration-line: underline;
  text-decoration-color: rgb(148, 148, 148);
  text-decoration-thickness: 2px;
  text-underline-offset: 12px;
  grid-row: 3;
}

.c1 {
  cursor: pointer;
  color:#fdfdfd !important;
}

@media only screen and (max-width: 1600px) {
  .wrapperContainer{
    padding: 80px 40px 20px 80px;
  }
  .a {
    padding-top:20px;
    grid-column: 1/8;
    font-size: 32px;
  }
  .b {
    padding-top:40px;
    grid-column: 1/8;
    font-size: 22px;
    line-height: 1.3;
    grid-row: 2;
  }

}



@media only screen and (max-width: 1200px) {
  .wrapperContainer{
    padding: 10px 40px 20px 40px;
  }
  .a {
    padding-top:20px;
    grid-column: 1/8;
    font-size: 28px;
  }
  .b {
    padding-top:40px;
    grid-column: 1/8;
    font-size: 18px;
    line-height: 1.3;
    grid-row: 2;
  }

}

@media only screen and (max-width: 600px) {

  .fillLogo {
    margin-top: 40px;
    margin-right: 10px;
  }

  .wrapperContainer {
    padding: 40px 20px 20px 20px;
  }


  .a {
    font-size: 28px;
  }

  .hide {
    display: none;
  }

}